/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/cold-calling-software.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/pronostico-llamadas-call-center.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/proveedores-telefonia-ip.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/auto-dialer-software.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/toip.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageMarcadorPredictivo/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageMarcadorPredictivo/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageMarcadorPredictivo/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageMarcadorPredictivo/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageFunctionalities/pageMarcadorPredictivo/images/accordionLeft/imageFive.webp"


import imageVideo from "@components/sharedComponents/bannerVideo/image/imagePredictivo.jpg"

import { TitleUnderlineMarcadorPredictivoOne, TitleUnderlineMarcadorPredictivoTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineMarcadorPredictivo"
import imageStartMarcadorPredictivo from "@components/pageFunctionalities/components/intro/images/imageStartMarcadorPredictivo.webp"

//iconos poteciamos operaciones
import televentas from "@components/pageHome/operations/images/imageFive.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageSeven.webp"

// logos testimonios
import RecuperaTes from "@components/sharedComponents/testimonies/images/logos/recupera.svg"
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"

//foto testimonio desktop
import recuperaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/recupera.png"
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"

//foto testimonio desktop
import recuperaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/recupera.png"
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"

const titleStart = (
  <p>
    Utiliza un Marcador Predictivo Cloud que te permita alcanzar los más <TitleUnderlineMarcadorPredictivoOne underline="altos niveles de" /> <TitleUnderlineMarcadorPredictivoTwo underline="contactabilidad" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Utiliza algoritmos inteligentes para anticipar la probabilidad de conexión con los clientes, asegurando así un flujo constante de comunicación efectiva y eficiente. Multiplica la productividad de tus agentes, aumenta el contacto de tu base de datos y asegura tus objetivos comerciales
        <br /><br />
        Optimiza tus campañas de contacto y maximiza las oportunidades de éxito mediante configuraciones dinámicas de marcado para reducir el tiempo de espera y las llamadas no conectadas.
      </p>
    ),
    image: imageStartMarcadorPredictivo,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  articles: [
    {
      image: article1,
      title: "14 preguntas para elegir el mejor software de Contact Center",
      textbody:
        "Te contamos qué consideraciones debes tener en cuenta para elegir el mejor software para call center y empieces a mejorar tus campañas.",
      link: "https://beexcc.com/blog/mejor-software-call-center/",
      card: true,
    },
    {
      image: article2,
      title: "Pronóstico de llamadas en call center: ¿Por qué es importante?",
      textbody:
        "Te contamos cuál es la importancia del pronóstico de llamadas en un call center y cuáles son los indicadores que te ayudan a medirlo.",
      link: "https://beexcc.com/blog/pronostico-llamadas-call-center/",
      card: true,
    },
    {
      image: article3,
      title: "7 proveedores de telefonía IP que debes conocer para este 2024",
      textbody:
        "Recopilamos los mejores proveedores de telefonía IP que todo Contact Center debe considerar para el 2024.",
      link: "https://beexcc.com/blog/proveedores-telefonia-ip/",
    },
    {
      image: article4,
      title: "Auto Dialer Software: Top 5 de las plataformas para el 2024",
      textbody:
        "Entérate cuáles son las 5 plataformas que te ofrecen Auto Dialer Software. Así elegirás la mejor para tu empresa.",
      link: "https://beexcc.com/blog/auto-dialer-software/",
    },
    {
      image: article5,
      title: "ToIP: Qué es, ventajas y en qué se diferencia vs VoIP",
      textbody:
        "¿Quieres saber todo sobre el ToIP? En este artículo te explicamos a detalle toda la información que necesitas sobre esta tecnología.",
      link: "https://beexcc.com/blog/toip/",
    },
  ],
  contacts: {
    title: "Mejora los resultados de tus llamadas outbound utilizando nuestro Marcador Progresivo",
    textbody: "Queremos ayudarte a potenciar tus estrategias de comunicación telefónica. Déjanos saber cuáles son tus necesidades y nuestros agentes se pondrán en contacto contigo.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionRight: [
    {
      title: "Precisión en la predicción",
      description: "<p>Contamos con un 98% de precisión al descartar números inexistentes o apagados y solo transferir llamadas respondidas por una voz humana. Además, determina el momento óptimo para contactar a cada lead, aumentando significativamente las tasas de respuesta.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Integración con herramientas externas",
      description: "<p>Unifica tus herramientas y procesos desde una única plataforma, logrando una vista 360° de tus clientes. Simplifica las operaciones de tu equipo al integrar herramientas externas como HubSpot, Zoho, SAP, entre otros.</p>",
      image: ImageAccordionLeftTwo,
    },
    {
      title: "Tratamiento a los no contestados",
      description: "<p>Nunca pierdas una oportunidad por una llamada no contestada, crea una estrategia de seguimiento tanto por llamada, como acciones de marcación manual, y también en canales digitales como notificaciones personalizadas por WhatsApp.</p>",
      image: ImageAccordionLeftThree
    },
    {
      title: "Inteligencia Artificial",
      description: "<p>Utiliza inteligencia artificial para analizar en tiempo real el tono y las necesidades de los clientes, ofreciendo a tus agentes recomendaciones instantáneas para personalizar la conversación y mejorar la satisfacción del cliente.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Escala tus campañas salientes",
      description: "<p>Contamos con una infraestructura flexible en la nube que permite adaptarse a las necesidades de tus campañas, permitiendo aumentar o reducir el volumen de tus campañas salientes sin comprometer la calidad.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "¿Cómo crear campañas de llamadas con marcadores predictivos?",
    description: "<p>El marcador predictivo en una campaña outbound de Contact Center ayuda a mejorar las tasas de conversión y a optimizar el tiempo de los agentes. <br /><br /> Por eso, nuestro CTO, Daniel Tito, nos hace un pequeño tutorial de cómo puedes lanzar campañas de marcación predictiva utilizando Beex.</p>",
    image: imageVideo
  },
  modules: [
    {
      tab: "Conversion",
      title: "Telemarketing",
      img: telemarketing,
      text:
        "Ajusta automáticamente el ritmo de las llamadas basándose en estadísticas en tiempo real, aumentando las conexiones con clientes potenciales y reduciendo el tiempo de espera. Maximiza la eficiencia del equipo, permitiendo más interacciones significativas en menos tiempo.",
      link: "/marketing-ventas/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Sales",
      title: "Ventas",
      img: televentas,
      link: "/software-cobranzas/",
      text:
        "Mejora las operaciones de televentas al predecir el mejor momento para llamar a los clientes, basándose en análisis de datos previos. Asegura que las llamadas se realicen cuando los clientes están más dispuestos a atender, incrementando las oportunidades de cierre.",
      colorTab: "#90ECF2",
      background: "#d3f7fa"
    },
    {
      tab: "Payment",
      title: "Cobros",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Optimiza las llamadas para alcanzar a más clientes en menos tiempo, priorizando aquellos con mayor probabilidad de pago. Agiliza el proceso de recuperación de deudas, manteniendo un flujo de caja saludable.",
      colorTab: "#FFAB99",
      background: "#FFD5CC"
    },
  ],
  testimonies: [
    {
      image: NetcallTes,
      title:
        "Con Beex y su marcador predictivo, hemos duplicado nuestras ventas mensuales",
      textbody:
        "Netcall Perú, BPO especializado en brindar soluciones al sector telecomunicaciones, ha automatizado procesos operativos, como backoffice, según sus reglas de negocio.",
      name: "Humberto Chumán",
      post: "Jefe de Proyectos",
      link: "/casos-de-exito/netcall-peru/",
      imgDesktop: netcallDesktop,
      imgMobile: netcallMobile,
      metrics: [
        {
          metric: "+25%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas mensuales",
          color: "#00CA56",
        },
      ],
    },
    {
      image: RecuperaTes,
      title:
        "Beex y su marcación predictiva ha logrado aumentar en +28% nuestra contactabilidad",
      textbody:
        "Grupo Recupera, experto en cobros para sector financiero, comercial, retail, servicios y más, ha mejorado sus índices de cobros con marcaciones inteligentes e IVR.",
      name: "Marco Salinas",
      post: "Gerente Comercial",
      link: "/casos-de-exito/grupo-recupera/",
      imgDesktop: recuperaDesktop,
      imgMobile: recuperaMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#FFB800",
        },
        {
          metric: "+28%",
          descripcion: "aumentó su contactabilidad",
          color: "#5831D8",
        },
        {
          metric: "90%",
          descripcion: "logró de índice de adherencia",
          color: "#00CA56",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ]
}

export default data
